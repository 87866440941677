<template>
  <div class="page">
    <h1>
      <w-icon>mdi mdi-rocket-launch-outline</w-icon>
      <br>
      みんなの あしたに ミヒラクストア
    </h1>
    <div class="content">
      <div class="cover-field">
        <w-image ref="cover" src="/image/home/cover/cover.jpg"></w-image>
      </div>
      <p>
        暗記のお供に 「
        <router-link to="/item/wake-ring/overview/">わけりんぐ</router-link>
        」 と 「
        <router-link to="/service/print/overview/">漢字プリントサービス</router-link>
        」 を 提供しています
      </p>
      <p>美味しい お米を みんなで シェア 「
        <router-link to="/service/nagasa-mai/secret/">お米のお持ち帰り</router-link>
        」 サービス 始めました
      </p>
    </div>
  </div>
</template>

<script>
import panzoom from 'panzoom'
export default {
  data() {
    return {
    }
  },
  mounted() {
    this.zoomer = panzoom(this.$refs.cover.$el, {
      maxZoom: 2,
      minZoom: 0.5,
      bounds: true,
      boundsPadding: 0.5
    })
    this.timer = setInterval(this.animation, 5000)
    setTimeout(() => {
      this.animation(0, 0, 0.5)
      setTimeout(() => {
        this.animation(0.5, 0.5, 1)
      }, 500)
    }, 500)
  },
  beforeUnmount() {
    this.zoomer.dispose()
    clearInterval(this.timer)
  },
  methods: {
    animation(randX, randY, zoom) {
      if (!this.$refs.cover) return
      const elm = this.$refs.cover.$el
      const wrapRect = elm.parentElement.getBoundingClientRect()
      const rect = elm.getBoundingClientRect()
      const x = -((rect.width - wrapRect.width) * (randX ||= Math.random()))
      const y = -((rect.height - wrapRect.height) * (randY ||= Math.random()))
      const z = zoom ||= Math.random() < 0.7 ? 0.75 : 1.5
      this.zoomer.smoothMoveTo(x, y)
      this.zoomer.smoothZoom(0, 0, z)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  .cover-field {
    margin-top: 30px;
    height: 50vh;
    overflow: hidden;
    background-image: radial-gradient(#f5e8f7 30%, transparent 0), radial-gradient(#f5e8f7 30%, transparent 0);
    background-size: 30px 30px;
    background-position: 0 0, 15px 15px;
  }
  h1 {
    line-height: 200%;
  }
  .content {
    p:first-of-type {
      margin-top: 50px
    }
    p {
      text-align: center;
    }
  }
}
</style>
